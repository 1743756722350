import React from 'react'
import { ButtonGroupProps } from 'react-multi-carousel'
import Icon from 'src/Components/Icons'

interface CarouselButtonGroupProps extends ButtonGroupProps {
  next: () => void
  previous: () => void
}

export const CarouselButtonGroup = ({ next, previous }: CarouselButtonGroupProps) => {
  const prevPressed = (e) => {
    e.stopPropagation()
    previous()
  }

  const nextPressed = (e) => {
    e.stopPropagation()
    next()
  }

  return (
    <div className="carousel-buttons golf-carousel-slider__arrows-wrapper golf-single-event__carousel">
      <button className="prev-button mr-10 secondary-outline-icon-bg-grey-btn" onClick={(e) => prevPressed(e)}>
        <Icon name="arrow" className={'golf-icon big-arrow left mr-0'} />
        <Icon name="arrow" className={'golf-icon big-arrow white-icon left ml-0'} />
      </button>
      <button className="next-button secondary-outline-icon-bg-grey-btn" onClick={(e) => nextPressed(e)}>
        <Icon name="arrow" className={'golf-icon big-arrow right mr-0'} />
        <Icon name="arrow" className={'golf-icon big-arrow white-icon right ml-0'} />
      </button>
    </div>
  )
}

export const responsiveCarousel = {
  desktop: {
    breakpoint: { max: 3000, min: 1280 },
    items: 4,
    partialVisibilityGutter: 20,
  },
  sm_desktop: {
    breakpoint: { max: 1300, min: 1280 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1279, min: 1024 },
    items: 3,
    partialVisibilityGutter: 20,
  },
  sm_tablet: {
    breakpoint: { max: 1023, min: 680 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  lg_mobile: {
    breakpoint: { max: 679, min: 524 },
    items: 2,
    partialVisibilityGutter: 0,
  },
  mobile: {
    breakpoint: { max: 523, min: 0 },
    items: 1,
    partialVisibilityGutter: 60,
  },
}
