import 'keen-slider/keen-slider.min.css'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { RectShape } from 'react-placeholder/lib/placeholders'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useCurrentWidth from 'src/hooks/useCurrentWidth'
import { Filter } from 'src/store/reducers/eventFilters'
import { EventFilters, EventType } from '../../Types/Event'
import { LocationsType } from '../../Types/Locations'
import StorySlider from '../Sliders/StorySlider'
import Navigation from './Navigation'
import Search from './Search'
import './styles.scss'
import Carousel from 'react-multi-carousel'
import { CarouselButtonGroup } from '../Carousel/Carousel'

interface HeaderProps {
  title?: string
  backgroundImage?: string
  hasSearch?: boolean
  hasSlider?: boolean
  isNavTransparent?: boolean
  isTall?: boolean
  images?: Array<string>
  backgroundImages?: Array<string>
  event?: EventType
  customerId?: string
  currentLocation?: LocationsType
  className?: string
  placeholder?: boolean
  filter: (filters: Partial<EventFilters>) => void
  hasCtaGG?: boolean
  stayTransparentSticky?: boolean
  goBackToRoute?: string
  hasSponsors?: boolean
  includeURLSearch?: boolean
}

const Header = (props: HeaderProps) => {
  const carouselRef = useRef(null)
  let history = useHistory()
  const isEventPreview = ['/preview-event', '/test', '/iframe'].some((route) =>
    history.location.pathname.includes(route),
  )
  const isHubPreview = ['/preview-hub'].some((route) => history.location.pathname.includes(route))

  const handleSearch = (value) => {
    props?.filter({ ...value })
    history.push('/events')
  }
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  let width = useCurrentWidth()

  const responsiveStyle = {
    desktop: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  }

  const next = () => {
    if (carouselRef.current) carouselRef.current.next()
  }

  const previous = () => {
    if (carouselRef.current) carouselRef.current.previous()
  }

  const heightClass = props.hasSponsors ? 'not-full-height' : 'h-screen'

  return (
    <Fragment>
      <header
        className={`golf-header ${!props.hasSlider && !props.isTall && !props.backgroundImage ? 'has-nav-only' : ''} ${
          props.isTall ? 'is-full-height' : ''
        } ${props.className ? props.className : ''} ${props.hasSponsors ? 'reduce-height' : ''}`}>
        {!isEventPreview && (
          <Navigation
            isTransparent={props.isNavTransparent}
            hasCtaGG={props.hasCtaGG}
            stayTransparentSticky={props.stayTransparentSticky}
            goBackToRoute={props.goBackToRoute ?? ''}
            includeURLSearch={props?.includeURLSearch}
            customerId={props.customerId || props?.event?.customer_id}
          />
        )}
        <div className={'golf-container'}>
          {!props.hasSlider && props.backgroundImage && (
            <figure
              className={'golf-header__singleImage mt-0 sm_tablet:mt-24'}
              style={{ backgroundImage: `url(${props.backgroundImage})` }}></figure>
          )}
          {props.hasSlider && props?.images.length > 0 && (
            <>
              {!loading && (
                <StorySlider
                  images={props?.images}
                  className={'golf-header__storySlider mt-0 sm_tablet:mt-24'}
                  placeholder={props.placeholder}
                />
              )}
              {loading && (
                <RectShape
                  className="golf-header__storySlider mt-0 sm_tablet:mt-24"
                  color="#E0E0E0"
                  style={{
                    width: width < 768 ? '100vw' : '100%',
                    height: width < 1024 ? '200px' : '240px',
                    marginRight: 0,
                  }}
                />
              )}
            </>
          )}
          {props.backgroundImages && (
            <div className="landing-carousel absolute tw-top-0 tw-left-0 -tw-z-1000">
              <Carousel
                ref={carouselRef}
                arrows={false}
                responsive={responsiveStyle}
                autoPlay
                autoPlaySpeed={5000}
                className={`w-screen ${isHubPreview ? 'preview-height' : heightClass}`}
                sliderClass={`${isHubPreview ? 'preview-height' : heightClass}`}
                customTransition="transform 1000ms ease-in-out"
                transitionDuration={1000}
                infinite={props.backgroundImages.length > 1}
                draggable={false}
                customButtonGroup={<CarouselButtonGroup next={next} previous={previous} />}
                renderButtonGroupOutside={false}>
                {props.backgroundImages.map((backgroundImage, index) => (
                  <img
                    className={`${isHubPreview ? 'preview-height' : heightClass} tw-object-cover w-screen`}
                    key={index}
                    src={backgroundImage}
                  />
                ))}
              </Carousel>
            </div>
          )}
        </div>
        {(props.title || props.hasSearch) && (
          <div className={'golf-container mt-auto'}>
            {props.title && <h1 className="mb-80 sm_tablet:mb-120 sm_desktop:mb-104">{props.title}</h1>}
            {props.hasSearch && <Search currentLocation={props.currentLocation} handleSearch={handleSearch} />}
          </div>
        )}
      </header>
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    filter: (filters: Partial<EventFilters>) => dispatch(Filter(filters)),
  }
}

export default connect(null, mapDispatchToProps)(Header)
