import './styles.scss'

import { deserialize } from 'jsonapi-fractal'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import EventsService from 'src/api/Events'
import Icon from 'src/Components/Icons'
import { SearchFilters, SearchResults } from 'src/Types/Event'

import { useDebounceFn } from 'ahooks'
import ClubsService from 'src/api/Clubs'
import HubsService from 'src/api/Hubs'
import Input from '../Inputs'
import SliderInput from '../Inputs/SliderInput'
import Content from './Content'

interface SearchProps {
  className?: string
  innerClassName?: string
  iconName?: string
  iconClassName?: string
  hideRadius?: boolean
  query?: string
  initialSearchQuery?: string
  initialShowSlider?: boolean
  initialSliderValue?: number
  hideLabel?: boolean
  pastEvent?: boolean
  type?: 'event' | 'club' | 'hub'
  eventTypes?: string[]
  defaultValue?: string | null
  onChange?: () => void
  filter: (filters: Partial<SearchFilters>) => void
  autoFocus?: boolean
  placeholder?: string
  includeEvents?: boolean
  includeCourses?: boolean
  includeCities?: boolean
  includeCustomers?: boolean
  includeZipCodes?: boolean
  hubFromLanding?: boolean
}

const deserializeResults = ({ events, cities, customers, courses, zip_codes }: any) => {
  const results: SearchResults = {
    events: events?.data?.length > 0 ? deserialize(events) : [],
    courses: courses?.data?.length > 0 ? deserialize(courses) : [],
    cities: cities?.data?.length > 0 ? deserialize(cities) : [],
    customers: customers?.data?.length > 0 ? deserialize(customers) : [],
    zip_codes: zip_codes?.data?.length > 0 ? deserialize(zip_codes) : [],
  }
  return results
}

function useOutsideAlerter(ref, hide) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) hide()
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [hide, ref])
}

const Search = (props: SearchProps) => {
  const [results, setResults] = useState<SearchResults>()
  const [isHidden, setIsHidden] = useState(true)
  const location = useLocation()
  const [showSlider, setShowSlider] = useState(props?.initialShowSlider || false)
  const [showClear, setShowClear] = useState(!isEmpty(props?.initialSearchQuery || ''))
  const [radiusSliderValue, setRadiusSliderValue] = useState(props?.initialSliderValue || 50)
  const [displayZipCodes, setDisplayZipCodes] = useState(false)

  const inputRef = useRef(null)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => setIsHidden(true))

  let { id } = useParams<{ id?: string }>()

  const onChange = useDebounceFn(
    (event) => {
      setShowClear(!isEmpty(event.target.value))
      if (isEmpty(event.target.value)) {
        onClear()
      } else {
        let request

        if (props.type == 'hub' || props.hubFromLanding) {
          request = HubsService.getSearchResults(
            id,
            event.target.value,
            props.pastEvent,
            !!props.includeCities,
            !!props?.includeCustomers,
            !!props?.includeEvents,
          )
        } else if (props.type == 'club') {
          request = ClubsService.getSearchResults({
            query: event.target.value,
            customer_id: id,
            past_event: props.pastEvent,
            event_type: props.eventTypes,
          })
        } else {
          request = EventsService.getSearchResults(
            event.target.value,
            !!props?.includeEvents,
            !!props?.includeCourses,
            !!props?.includeCities,
            !!props?.includeCustomers,
            !!props?.includeZipCodes && displayZipCodes,
          )
        }

        request
          .then(({ data }: any) => {
            setResults(deserializeResults(data))
            setIsHidden(false)
          })
          .catch((err) => console.error(err))
      }
    },
    {
      wait: 300,
    },
  )

  const onClear = useCallback(() => {
    inputRef.current.value = null
    setIsHidden(true)
    setShowSlider(false)
    setShowClear(false)
    setResults({
      events: [],
      courses: [],
      cities: [],
      customers: [],
      zip_codes: [],
    })
    props.filter(null)
  }, [props])

  useEffect(() => {
    if (!props.query) {
      inputRef.current.value = null
      setShowSlider(false)
    }
    setShowClear(!isEmpty(props.query))
  }, [props.query])

  useEffect(() => {
    setShowSlider(props?.initialShowSlider || false)
  }, [props?.initialShowSlider])

  useEffect(() => {
    setRadiusSliderValue(props?.initialSliderValue || 50)
  }, [props?.initialSliderValue])

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then((raw) => raw.json())
      .then((res) => setDisplayZipCodes(res.country_code === 'US' || res.country_code === 'RO'))
      .catch((err) => console.error(err))
  }, [])

  if (!inputRef && !inputRef.current && props?.defaultValue) inputRef.current.value = props?.defaultValue

  return (
    <div ref={wrapperRef} className={`search-filter ${props.className}`}>
      <div
        className={`d-flex ${props.innerClassName ?? ''} ${
          props.className?.includes('mobile') ? 'flex-col-reverse' : ''
        }`}>
        <div className={`search-filter__search-wrapper tw-w-[470px] ${props.innerClassName ?? ''}`}>
          <Input
            autoComplete="off"
            ref={inputRef}
            key={props?.initialSearchQuery}
            defaultValue={
              location.pathname.includes('/events/list') ? props?.initialSearchQuery : props?.defaultValue ?? ''
            }
            type="text"
            className="search-filter__search !tw-pl-12"
            name="query"
            wrapperClassName="tw-w-full md:tw-w-auto"
            placeholder={
              displayZipCodes
                ? props.placeholder
                : props.placeholder
                    .replace('or Zip Code', '')
                    .replace(', Zip Code', '')
                    .replace('Zip Code or ', '')
                    .replace('Zip Code, ', '')
            }
            onChange={onChange.run}
            autoFocus={props.autoFocus}
            onClick={() => {
              if (props?.includeCities) {
                setIsHidden(false)
              }
              setResults({
                events: [],
                courses: [],
                cities: [],
                customers: [],
                zip_codes: [],
                ...(props.query && { ...results }),
              })
            }}
          />
          {showClear ? (
            <Icon
              name="close-icon"
              onClick={onClear}
              className="golf-icon orange-icon tw-cursor-pointer hover:tw-bg-gray-100 tw-rounded-full tw-p-0.5 tw-transition-all tw-duration-200 !tw-top-[7px] !tw-right-auto"
            />
          ) : (
            <Icon
              name={props.iconName ?? 'search'}
              className={props.iconClassName ?? 'golf-icon orange-icon !tw-top-[7px] !tw-right-auto'}
            />
          )}
        </div>
        {/* {showSlider && !props.hideRadius && (
          <div
            className={`${
              props.className?.includes('mobile') ? 'mt-16' : props.hideLabel ? 'mr-12' : '-mt-20'
            } tw-ml-5`}>
            {!props.hideLabel && <p className={'sm-height tw-flex tw-font-extrabold'}>Radius of (miles)</p>}
            <SliderInput
              onSlide={(value) => {
                props.filter({ radius: value })
                setRadiusSliderValue(value)
              }}
              radiusValue={radiusSliderValue}
              suffix="miles"
            />
          </div>
        )} */}
      </div>
      {results && !isHidden && (
        <Content
          results={results}
          searchText={inputRef.current.value}
          includeCurrentLocation={!!props?.includeCities}
          filter={(filters, query) => {
            const newFilters = filters.filter_latitude
              ? {
                  ...filters,
                  radius: filters.radius ?? 50,
                  location_query: query,
                }
              : {
                  ...filters,
                  query,
                }
            inputRef.current.value = query
            props.filter(newFilters)
            setIsHidden(true)
            setShowSlider(newFilters.filter_latitude != null)
          }}
          onChange={() => {
            props.onChange && props.onChange()
            setRadiusSliderValue(50)
          }}
        />
      )}
    </div>
  )
}

export default Search
